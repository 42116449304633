.services-section-card:hover > .services-section-card-aboveDiv {
    display: block;
}

.flip-in-ver-left:hover {
	animation: flip-in-ver-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes flip-in-ver-left {
    0% {
      transform: rotateY(80deg);
      opacity: 0;
    }
    100% {
      transform: rotateY(0);
      opacity: 1;
    }
  }
  

  .scale-up-br:hover {
	animation: scale-up-br 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


 @keyframes scale-up-br {
    0% {
      transform: scale(0.5);
      transform-origin: 100% 100%;
    }
    100% {
      transform: scale(1);
      transform-origin: 100% 100%;
    }
  }
  

@media screen and (max-width: 2000px) {
  
}

@media screen and (max-width: 1150px) {
  .services-section-comp {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
}
  
@media screen and (max-width: 650px) {
  
} 
  
  