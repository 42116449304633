@media screen and (max-width: 2000px) {
    
}
@media screen and (max-width: 1150px) {
    .latestNewsComp-section {
        padding: 1rem;
    }
    
    .latestNewsComp-section-card {
        min-width: fit-content;
        width: 100%;
    }
}
@media screen and (max-width: 650px) {
    
}