@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .blog-section-cards {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 650px) {
    
}