@media screen and (max-width: 2000px) {
    
}
@media screen and (max-width: 1150px) {
    .singleServicePage-section-main {
        padding: 0;
        flex-direction: column;
    }
    .singleServicePage-section-main > div {
        width: 100%;
    }
    .singleServicePage-section-content {
        padding: 1rem;
        gap: 1rem;
    }
    .singleServicePage-section-main-left > div {
        flex-direction: column;
        padding: 0;
        gap: 1rem;
    }
    .singleServicePage-section-main-left > div > img {
        width: 100%;
    }
    .singleServicePage-section-main-left {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
@media screen and (max-width: 650px) {
    
}