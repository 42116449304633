@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .HeroSectionOfPagesComp {
        padding-top: 4rem;
    }
    .HeroSectionOfPagesComp-arrowImg {
        width: 15px;
    }
    .HeroSectionOfPagesComp > div > img {
        height: 300px;
    }
    .HeroSectionOfPagesComp-main-heading {
        font-size: 30px;
    }
}

@media screen and (max-width: 650px) {
    
}