.header-section-links > p {
    cursor: pointer;
}

.header-section-hamIcon {
    display: none;
}

.header-section-mobile-dropdown {
    display: none;
}

/* .header-section-links {
    display: flex !important;
    flex-direction: row !important;
    gap: 2rem !important;
    color: white !important;
} */

@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .header-section-links {
        display: none;
    }

    .header-section-main {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .header-section-logo > img {
        width: 120px;
    }
    .header-section-contact-btn {
        display: none;
    }
    .header-section-hamIcon {
        display: flex;
    }
    .header-section-main {
        background-color: #272727;
    }
    .header-section-mobile-dropdown > p {
        color: #B0B0B0;
    }
    .header-section-mobile-dropdown > p:hover {
        color: #000000;
    }
    .header-section-mobile-dropdown {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    
}