.footer-section-content > div {
    width: 25%;
}

@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .footer-section-upper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-section-upper > img {
        width: 50%;
    }
    .footer-section-content {
        padding: 1rem;
        flex-direction: column;
        gap: 1rem;
    }
    .footer-section-content > div {
        width: 100%;
    }
    .footer-section-lower {
        padding: 1rem;
        /* flex-direction: column;
        gap: 10px; */
    }
}

@media screen and (max-width: 650px) {
    
}