.contact-section-comp {
    background: linear-gradient(0deg, #272727 6.66%, rgba(39, 39, 39, 0.00) 100%);
}

@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .contact-section-comp-heading {
        font-size: 30px;
    }
    .contact-section-comp-content {
        flex-direction: column;
        align-items: normal;
        gap: 2rem;
    }
    .contact-section-comp-content1 {
        gap: 4px;
    }
    .contact-section-comp-content2 {
        gap: 4px;
    }
    .contact-section-comp-content3 {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    
}