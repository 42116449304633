@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .singleProjectPage-section-main {
        padding: 1rem;
        flex-direction: column;
    }
    .singleProjectPage-section-main > div {
        width: 100%;
    }
    .singleProjectPage-section-main-left > div {
        padding: 0;
        flex-direction: column;
        gap: 1rem;
    }
    .singleProjectPage-section-main-left > div > img {
        width: 100%;
    }
    .singleProjectPage-section-main-left {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 650px) {
    
}