@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .services-section-content {
        padding: 1rem;
    }
}

@media screen and (max-width: 650px) {
    
}