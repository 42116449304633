@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .contactUs-section-main {
        padding: 1rem;
        flex-direction: column;
        gap: 2rem;
    }
    .contactUs-section-main > div {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    
}