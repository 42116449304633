.homepage-section-hero-section-services-cards1 > div {
    width: 33.33%;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    
}
.homepage-section-hero-section-services-cards2 > div {
    width: 33.33%;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    
}

@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .homepage-section-hero-section-services-cards1 {
        position: relative;
        flex-direction: column;
    }
    .homepage-section-hero-section-services-cards1 > div {
        width: 100%;
    }
    .homepage-section-hero-section-services-cards2 {
        flex-direction: column;
        justify-content: center;
    }

    .homepage-section-hero-section-services-cards2 > div {
        width: 100%;
    }
    .homepage-section-aboutus {
        flex-direction: column-reverse;
        padding: 1rem;
    }
    .homepage-section-aboutus > div {
        width: 100%;
    }
    .homepage-section-aboutus > img {
        width: 100%;
    }
    .ourProjects-section-headingandViewAll {
        padding: 1rem;
    }
    .ourProjects-section-headingandViewAll > p {
        font-size: 30px;
    }
    .homepage-section-blog-heading {
        padding: 1rem;
        font-size: 30px;
    }
    .hero-section-maindiv-heading {
        font-size: 40px;
    }
    .hero-section-maindiv {
        padding: 2rem;
        gap: 10px;
    }
    .homepage-section-contactImage {
        padding: 0;
    }
    .homepage-section-contactSection {
        top: 0;
        height: 100%;
    }
    .homepage-section-contact > img {
        
    }
}

@media screen and (max-width: 650px) {
    
}