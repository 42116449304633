@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .aboutus-section-25YEARSOFEXPERIENCE {
        flex-direction: column;
        padding: 1rem;
    }
    .aboutus-section-25YEARSOFEXPERIENCE > div {
        width: 100%;
        gap: 1rem;
    }
    .aboutus-section-25YEARSOFEXPERIENCE > img {
        width: 100%;
    }
    .aboutus-section-25YEARSOFEXPERIENCE > div > h3 {
        font-size: 30px;
    }
    .aboutus-section-contact > img {
        height: 400px;
    }
    .aboutus-sectionourVisionANDOurMission {
        padding: 1rem;
    }
}

@media screen and (max-width: 650px) {
    
}