@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .singleBlogPage-section-main {
        padding: 1rem;
    }
    .singleBlogPage-section-main-image > img {
        height: 300px;
    }
    .singleBlogPage-section-main-image-text {
        padding: 1rem;
    }
    .singleBlogPage-section-main-textpart {
        padding: 1rem;
    }
    .page-Contact-form {
        padding: 1rem;
    }
}

@media screen and (max-width: 650px) {
    
}