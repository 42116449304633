@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .aboutUs-ourClients-comp > img {
        height: 650px;
    }
    
    .aboutUs-ourClients-comp-head {
        display: block;
        /* position: unset; */
    }
    .aboutUs-ourClients-comp-cards {
        padding: 1rem;
    }
    .aboutUs-ourClients-comp-card {
        height: 400px;
    }
}

@media screen and (max-width: 650px) {
    
}