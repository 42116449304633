.ourProjects-section-categories > p {
    cursor: pointer;
}

@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .ourProjects-section {
        padding: 1rem;
    }
    .ourProjects-section-categories {
        flex-direction: column;
        gap: 8px;
    }
    .ourProjects-section-cards {
        grid-template-columns: 1fr;
    }
    .ourProject-section-card {
        padding: 1rem;
    }
    .ourProject-section-card > img {
        height: 400px;
    }
    .ourProjects-section-categories > p {
        font-size: 20px;
    }
    .ourProject-section-card > div {
        gap: 8px;
        width: 80%;
    }
    .ourProject-section-card > div > h3 {
        font-size: 20px;
    }
    .ourProject-section-card > div > p {
        font-size: 14px;
    }
}

@media screen and (max-width: 650px) {
    
}