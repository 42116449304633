@media screen and (max-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .aboutus-section-creativeTeam-section-content {
        padding: 1rem;
    }
    .aboutus-section-creativeTeam-section-content {
        display: flex;
        flex-direction: column;
        position: unset;
        gap: 1rem;
        
    }
    .aboutus-section-creativeTeam-section > img {
        display: none;
    }
    /* .creativeTeam-card > img {
        width: 100px;
        height: 100px;
    } */
    .creativeTeam-card > div > p {
        font-size: 12px;
    }
    .creativeTeam-card {
        /* justify-content:center; */
        gap: 1rem;
    }
    .creativeTeam-card-icons {
        display: flex !important;
        flex-direction: row !important;
    }
}

@media screen and (max-width: 650px) {
    
}